
import { Vue, Component, Ref } from "vue-property-decorator";
import { mapMutations } from "vuex";

import * as types from "@/store/types";
import { Header } from "@/components/layout/FilterableTable.vue";
import {
    UserFormInterface,
    User,
    UserFormActions,
    ResponseDataUser
} from "@/interfaces";

@Component({
    components: {
        FilterableTable: () =>
            import("@/components/layout/FilterableTable.vue"),
        DialogBase: () => import("@/components/layout/DialogBase.vue"),
        UserForm: () => import("@/pages/components/forms/UserForm.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    }
})
export default class UserManagement extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;

    @Ref("form") readonly userFormActions!: UserFormActions;

    headers: Header[] = [
        {
            text: "Username",
            align: "start",
            filterable: true,
            sortable: true,
            value: "username"
        },
        {
            text: "Email",
            align: "start",
            filterable: true,
            sortable: true,
            value: "email"
        },
        {
            text: "Role",
            align: "start",
            filterable: false,
            sortable: false,
            value: "role"
        },
        {
            text: "Actions",
            align: "start",
            filterable: false,
            sortable: false,
            value: "actions"
        }
    ];

    items: User[] = [];

    userForm: UserFormInterface = {
        valid: true,
        username: "",
        email: "",
        password: "",
        role: []
    };

    selectedUser: User = {
        username: "",
        email: "",
        password: "",
        role: []
    };

    dialogWidth?: number = 900;

    syncDialogCreateShow: boolean | null = false;
    syncDialogEditShow: boolean | null = false;
    syncDialogDeleteShow: boolean | null = false;

    mounted() {
        this.getAllUsers();
    }

    /**
     * Methods
     */
    getAllUsers() {
        this.$api.getUsers<ResponseDataUser>().then(response => {
            this.items = response.data.users;
        });
    }

    showCreateDialog() {
        this.syncDialogCreateShow = true;
    }

    closeCreate() {
        this.userFormActions.reset();
        this.syncDialogCreateShow = false;
    }

    createUser() {
        this.setProgressBar(true);
        this.$api
            .createUser(this.userForm)
            .then(() => {
                this.getAllUsers();
                this.showError("User successfully created.");
            })
            .catch(() => {
                this.showError("Error creating new user.");
            })
            .finally(() => {
                this.setProgressBar(false);
                this.closeCreate();
            });
    }

    showDeleteDialog(user: User) {
        this.selectedUser = user;
        this.syncDialogDeleteShow = true;
    }

    closeDelete() {
        this.syncDialogDeleteShow = false;
    }

    deleteUser() {
        this.setProgressBar(true);
        this.$api
            .deleteUser(this.selectedUser)
            .then(() => {
                this.getAllUsers();
                this.showError("User successfully deleted.");
            })
            .catch(() => {
                this.showError("Error deleting the user.");
            })
            .finally(() => {
                this.setProgressBar(false);
                this.syncDialogDeleteShow = false;
            });
    }

    showEditDialog(user: User) {
        this.selectedUser = user;
        this.selectedUser.password = "";
        this.syncDialogEditShow = true;
    }

    closeEdit() {
        this.syncDialogEditShow = false;
    }

    editUser() {
        this.setProgressBar(true);
        this.$api
            .updateUser(this.selectedUser)
            .then(() => {
                this.getAllUsers();
                this.showError("User successfully updated.");
            })
            .catch(() => {
                this.showError("Error updating user.");
            })
            .finally(() => {
                this.setProgressBar(false);
                this.closeEdit();
            });
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
}
